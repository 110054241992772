import Head from "@/components/Head/index.vue";
import Tabs from "@/components/Tabs/index.vue";
import Pagination from "@/components/Pagination/index.vue";
import { defineComponent, onActivated, reactive, watch, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import List from "./components/List.vue";
export default defineComponent({
  name: "ExamIndex",
  components: {
    Tabs: Tabs,
    Head: Head,
    Pagination: Pagination,
    List: List
  },
  setup: function setup() {
    var router = useRouter();
    var table1 = ref();
    var table2 = ref();
    var Data = reactive({
      titleArr: ["待考试项目", "已考试项目"],
      tabsIndex: 0
    }); // 获取列表

    function getList() {
      nextTick(function () {
        if (Data.tabsIndex == 0) {
          table1.value.initData();
        } else {
          table2.value.initData();
        }
      });
    } // onMounted(() => {
    //    getList();
    // });
    // 获取Tab索引值


    var tabsChange = function tabsChange(val) {
      Data.tabsIndex = val;
    }; // 监听tabs索引值改变、pager分页改变


    watch(function () {
      return [Data.tabsIndex];
    }, function () {
      getList();
    }); // 查看详情

    var handleDetail = function handleDetail(item) {
      router.push({
        path: "/personal/ExamBeforeDetail",
        query: {
          historyId: item.historyId,
          state: "detail"
        }
      });
    }; // 开始考试


    var handleStart = function handleStart(item) {
      router.push({
        path: "/personal/ExamBeforeDetail",
        query: {
          historyId: item.historyId,
          state: "start"
        }
      });
    }; //刷新列表


    onActivated(function () {
      getList();
    });
    return {
      Data: Data,
      table1: table1,
      table2: table2,
      getList: getList,
      tabsChange: tabsChange,
      handleDetail: handleDetail,
      handleStart: handleStart
    };
  }
});